'use client';
import React, { useEffect, useState } from 'react';
import DOMPurify from 'isomorphic-dompurify';

interface TextWithLinksProps {
  text: string;
  amountOfWords?: number;
  isSinglePost?: boolean;
  isComment?: boolean;
  useCase?: string;
  alwaysShowFullText?: boolean;
}

const TextWithLinksComponent: React.FC<TextWithLinksProps> = ({
  text,
  amountOfWords = 66,
  isSinglePost = false,
  isComment,
  useCase,
  alwaysShowFullText = false,
}) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [showFullText, setShowFullText] = useState(alwaysShowFullText);
  const [beginText, setBeginText] = useState('');
  const [endText, setEndText] = useState('');

  let renderText = React.useMemo(() => {
    const clean = DOMPurify.sanitize(text);
    const urlRegex =
      /((https?:\/\/)?((www\.)?|[a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?)|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
    const modifiedText = clean.replace(urlRegex, (match) => {
      const isEmail = match.includes('@');
      if (isEmail) {
        return `<a href="mailto:${match}" style="color: blue;">${match}</a>`;
      } else {
        let url = match;
        if (!/^https?:\/\//i.test(url)) {
          url = `https://${url}`;
        }
        return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-primary">${match}</a>`;
      }
    });
    return useCase === 'messaging' ? modifiedText : clean;
  }, [text, useCase]);

  useEffect(() => {
    if (!isSinglePost && !alwaysShowFullText) {
      const splittedText = renderText.split(' ');
      const isTruncatedNow = splittedText.length > amountOfWords;
      setBeginText(
        isTruncatedNow
          ? showFullText
            ? splittedText.slice(0, amountOfWords - 1).join(' ')
            : splittedText.slice(0, amountOfWords - 1).join(' ') + '...'
          : renderText,
      );
      setEndText(splittedText.slice(amountOfWords - 1).join(' '));
      setIsTruncated(isTruncatedNow);
    } else {
      setIsTruncated(false);
      setBeginText(renderText);
    }
  }, [renderText, amountOfWords, showFullText, isSinglePost, alwaysShowFullText]);

  const handleClick = () => {
    if (!alwaysShowFullText) {
      if (useCase === 'posts') {
        !isSinglePost && isComment && setShowFullText(!showFullText);
      } else if (useCase === 'messaging') {
        setShowFullText(!showFullText);
      }
    }
  };

  return (
    <p className="editor-content">
      <span
        className="ProseMirror"
        style={{ lineHeight: '1.5', transition: 'max-height 0.3s ease-out' }}>
        <span dangerouslySetInnerHTML={{ __html: beginText }} />
        {isTruncated && !alwaysShowFullText && (
          <>
            {showFullText && <span dangerouslySetInnerHTML={{ __html: endText }} />}
            <span className="text-primary cursor-pointer" onClick={handleClick}>
              {showFullText ? 'see less' : 'read more'}
            </span>
          </>
        )}
      </span>
    </p>
  );
};

export const TextWithLinks = React.memo(TextWithLinksComponent);
