'use client';
import { useToast } from '@ui/components';
import { ALLOWED_FILE_TYPES } from '@ui/hooks/file/constants';
import React, { ReactNode, useRef } from 'react';

interface FileUploadProps {
  children: ReactNode;
  exceededFileLimit: boolean;
  maxFiles: number;
  className?: string;
  onSelectFiles?: (fileList: FileList) => void;
}
export const InputFileWithTrigger: React.FC<FileUploadProps> = ({
  children,
  onSelectFiles,
  exceededFileLimit,
  className,
  maxFiles,
}) => {
  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (exceededFileLimit) {
      toast({ title: `You can't upload more than ${maxFiles} files` }, { type: 'warning' });

      return;
    }

    if (e.target.files && onSelectFiles) {
      onSelectFiles(e.target.files);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <input
        type="file"
        multiple
        style={{ display: 'none' }}
        ref={fileInputRef}
        accept={ALLOWED_FILE_TYPES.join(',')}
        onChange={handleFileChange}
      />
      <span onClick={handleClick} className={className}>
        {children}
      </span>
    </>
  );
};
