import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ScoreAnimation } from '@ui/hooks';
import Lottie from 'lottie-react';
import animationData from './score-confetti.json';
import { usePostHogFeatureFlag } from '@ui/hooks';
import { Plus } from 'lucide-react';

interface ScoreAnimationComponentProps {
  className?: string;
  scoreAnimation: ScoreAnimation;
  visiblePositions: ScoreAnimation['position'][];
  userType: string;
}

const AnimatedScoreIncrease: React.FC<ScoreAnimationComponentProps> = ({
  className = '',
  scoreAnimation,
  visiblePositions,
  userType,
}) => {
  const flagEnabled = usePostHogFeatureFlag('score-engagement');

  if (!flagEnabled || !userType || userType === 'creator') {
    return;
  }

  const isVisible = scoreAnimation.isVisible && visiblePositions.includes(scoreAnimation.position);

  return (
    <AnimatePresence>
      {isVisible && (
        <div className={`overflow-visible pointer-events-none ${className}`}>
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.5 }}
            className="">
            <Plus className="w-4 h-4" strokeWidth={4} />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: -40 }}
            exit={{ opacity: 0, y: -35 }}
            transition={{ duration: 0.6 }}
            className=" w-[120px] absolute top-0 left-[-50px] overflow-visible">
            <Lottie
              animationData={animationData}
              loop={true}
              autoplay={true}
              width={120}
              height={100}
              // className="w-[100px] h-[100px]"
            />
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default AnimatedScoreIncrease;
