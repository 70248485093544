'use client';
import { ReactNode, useState, useMemo } from 'react';
import {
  Button as KorButton,
  CohortSelectionModal,
  ConversationMessageInput,
  CustomLeaderboardSelectionModal,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  EasyTab,
  EasyTabContent,
  EasyTabItem,
  Icon,
  ICreateConversation,
  IMessagePayload,
  Input,
  IOption,
  TConversationParticipantSource,
  useConversationContext,
  useMessageContext,
} from '@ui/components';
import { Terminal } from 'lucide-react';
import { ConversationParticipantOption } from './conversation-participant-option';
import { ConversationParticipantSelectionCount } from './conversation-participant-selection-count';
import { Button } from '@ui/components/ui/button';
import { EmailsSelectionModal } from './email-selection-modal';

const messageInitialState: IMessagePayload = {
  user_id: '',
  description: '',
  files: {
    images: [],
  },
  send_email: true,
};

const initial: ICreateConversation = {
  description: '',
  name: '',
  creator_id: '',
  restrictions: {
    fans_reply: false,
  },
  public: false,
};
interface IProps {
  children: ReactNode;
}
export const CreateConversationModal: React.FC<IProps> = ({ children }: IProps) => {
  const state = useConversationContext();
  const messageState = useMessageContext('creator');
  const [isOpen, setIsOpen] = useState(false);
  const [conversation, setConversation] = useState<ICreateConversation>(initial);
  const [participantSource, setParticipantSource] = useState<TConversationParticipantSource | null>(
    null,
  );
  const [message, setMessage] = useState<IMessagePayload>(messageInitialState);
  const isDisabled = useMemo(() => {
    const hasLeaderboard =
      participantSource &&
      participantSource.type === 'leaderboard' &&
      participantSource.custom_leaderboard_id;
    const hasCohort =
      participantSource && participantSource.type === 'cohort' && participantSource.cohort_id;
    const hasEmails =
      participantSource &&
      participantSource.type === 'email' &&
      participantSource.emails.length > 0;
    const hasBasics = conversation.name && message.description;
    const hasSource = hasLeaderboard || hasCohort || hasEmails;
    return !hasBasics || state.isCreatingConversation || !hasSource;
  }, [state.isCreatingConversation, conversation.name, message.description, participantSource]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setConversation({ ...conversation, name: e.target.value });
  const onAddMembers = (participantSource: TConversationParticipantSource) =>
    setParticipantSource(participantSource);
  const onMessageChange = (change: IMessagePayload) =>
    setMessage((state) => ({ ...state, ...change }));

  const handleCreate = async () => {
    try {
      const response = await state.onCreateConversation({
        ...conversation,
        ...participantSource,
        creator_id: state.creator_id,
      });

      if ('data' in response) {
        setIsOpen(false);
        setParticipantSource(null);
        setConversation(initial);
        state.onResetPage();
        state.setSelectedConversationSet(null);

        // create message
        await messageState.onCreateMessage({
          ...message,
          thread_id: response.data.conversation.main_thread,
          creator_id: state.creator_id,
          user_id: state.creator_id,
        });

        setMessage(messageInitialState);
      }
    } catch (error) {}
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent
        className="bg-white p-6 sm:max-w-lg"
        style={{ cursor: state.isCreatingConversation ? 'progress' : 'default' }}>
        <DialogHeader>
          <DialogTitle className="text-2xl">New Message</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-4 space-y-4">
          <div>
            <Input type="text" placeholder="e.g exclusive-content" onChange={handleNameChange} />
            <div className="mt-4 flex flex-row gap-2 text-xs">
              <Terminal className="h-6 w-8" />
              <div className="flex flex-col">
                <span className="text-sm font-medium">Recommended to use a unique name</span>
                <span className="">Start a new message thread, engage, and share content.</span>
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <p className="text-muted-foreground font-medium">Add Participants</p>
            <div className="flex flex-col justify-between space-y-4">
              <EasyTab
                defaultTab="From Leaderboards"
                className="min-h-[80px] overflow-hidden space-y-4">
                <div className="flex">
                  <EasyTabItem
                    label="From Leaderboards"
                    className="p-2 border-t border-b border-l rounded-l-sm"
                    useBottomActiveBorder={false}
                    useFullBorderWhenActive
                  />
                  <EasyTabItem
                    label="From Cohorts"
                    className="p-2  border-t border-b border-l"
                    useBottomActiveBorder={false}
                    useFullBorderWhenActive
                  />
                  <EasyTabItem
                    label="From Email"
                    className="p-2 border rounded-r-sm"
                    useBottomActiveBorder={false}
                    useFullBorderWhenActive
                  />
                </div>

                <EasyTabContent label="From Leaderboards" className="min-h-fit">
                  <CustomLeaderboardSelectionModal onAddMembers={(e) => onAddMembers(e)}>
                    {participantSource?.type === 'leaderboard' ? (
                      <ConversationParticipantSelectionCount selection={participantSource} />
                    ) : (
                      <Button variant="outline" size="sm" className="rounded-sm h-9">
                        <Icon type="plus" className="mr-2 rounded-full" /> Select Leaderboard
                      </Button>
                    )}
                  </CustomLeaderboardSelectionModal>
                </EasyTabContent>

                <EasyTabContent label="From Cohorts" className="min-h-fit">
                  <CohortSelectionModal onAddMembers={(e) => onAddMembers(e)}>
                    {participantSource?.type === 'cohort' ? (
                      <ConversationParticipantSelectionCount selection={participantSource} />
                    ) : (
                      <Button variant="outline" size="sm" className="rounded-sm h-9">
                        <Icon type="plus" className="mr-2 rounded-full" /> Select Cohort
                      </Button>
                    )}
                  </CohortSelectionModal>
                </EasyTabContent>

                <EasyTabContent label="From Email" className="min-h-fit">
                  <EmailsSelectionModal onAddMembers={(e) => onAddMembers(e)}>
                    {participantSource?.type === 'email' ? (
                      <ConversationParticipantSelectionCount selection={participantSource} />
                    ) : (
                      <Button variant="outline" size="sm" className="rounded-sm h-9">
                        <Icon type="plus" className="mr-2 rounded-full" /> Add Emails
                      </Button>
                    )}
                  </EmailsSelectionModal>
                </EasyTabContent>
              </EasyTab>
            </div>
          </div>

          <div>
            <ConversationMessageInput
              className="relative w-full"
              innerClassName="p-0"
              useInputOnly
              onChange={onMessageChange}
              textAreaClassName="min-h-[100px]"
              fileRenderDivClassName="border-none"
            />
          </div>
        </div>

        <DialogFooter className="justify-end">
          <KorButton
            type="primary"
            size="small"
            isLoading={state.isCreatingConversation}
            disabled={isDisabled}
            onClick={handleCreate}>
            Create
          </KorButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
