import { twMerge } from 'tailwind-merge';

const TabButtonStyles = {
  active: 'is-active bg-tabActive text-primary cursor-default',
  default: 'bg-input text-note hover:bg-inputActive active:bg-secondaryActive',
};

interface ITabButton {
  type: keyof typeof TabButtonStyles;
  className?: string;
  value?: string;
  disabled?: boolean;
  onValue?(value: any): void;
  onClick?(): void;
}

// TODO: combine with button
export function TabButton({
  children,
  type,
  className,
  value,
  disabled,
  onClick,
  onValue,
}: React.PropsWithChildren<ITabButton>) {
  function onPress() {
    if (onClick) {
      onClick();
      return;
    }

    if (onValue && value) {
      return onValue(value);
    }
  }

  return (
    <button
      disabled={disabled}
      className={twMerge(
        'tab-btn disabled:bg-input min-w-[106px] rounded-lg px-4 py-4 transition-colors disabled:opacity-60',
        TabButtonStyles[type],
        className,
      )}
      onClick={onPress}>
      {children}
    </button>
  );
}
