'use client';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useId } from 'react';
import { Icon, Text } from '../atoms';
import { twMerge } from 'tailwind-merge';

interface ITooltip {
  message: string;
  place?: 'top' | 'right';
  small?: boolean;
  error?: boolean;
  useTextReplaceIcon?: string;
  className?: string;
}
export function CustomTooltip({
  message,
  place = 'top',
  small,
  error,
  useTextReplaceIcon,
  className,
}: ITooltip) {
  const id = useId();

  const arrowStyles = {
    top: 'rotate-[45deg] left-1/2 -translate-x-2.5 -bottom-1.5',
    right: 'rotate-[135deg] -left-1.5 top-1/2 -translate-y-2.5',
  };

  return (
    <>
      <a data-tooltip-id={id}>
        {useTextReplaceIcon ? (
          <span className=" font-semibold">{useTextReplaceIcon}</span>
        ) : (
          <Icon
            type="info"
            stroke={error ? '#CB2401' : '#2D3139'}
            width={small ? 14 : 16}
            height={small ? 14 : 16}
          />
        )}
      </a>
      <ReactTooltip
        id={id}
        className={twMerge(
          '!border-border !z-20 max-w-[350px] !rounded-md !border !bg-white !p-4 !opacity-100',
          className,
        )}
        classNameArrow="!border-b !border-r !border-border"
        noArrow={true}
        place={place}>
        <Text type="s" className="font-medium">
          {message}
        </Text>
        <div
          className={twMerge(
            'border-border absolute aspect-square h-2.5 rotate-[135deg] border-b border-r bg-white',
            arrowStyles[place],
          )}
        />
      </ReactTooltip>
    </>
  );
}
