import { useState, useCallback } from 'react';
import { ALLOWED_FILE_TYPES, MAX_FILE_SIZE } from './constants';
import { IFile, IFileMeta, T_FILE_MIME_ENUM_TYPE } from './types';
import { generateUniqueId } from '@ui/constants';
import { useToast } from '@ui/components';
import { sanitizeFilename } from '@ui/lib/utils';

interface Props {
  maxFiles?: number;
}

export function useFiles({ maxFiles = 4 }: Props = {}) {
  const toast = useToast();
  const [files, setFiles] = useState<IFile[]>([]);
  const [error, setError] = useState<string | null>(null);

  const processFileMeta = useCallback((fileMeta: IFileMeta): IFile | null => {
    if (!ALLOWED_FILE_TYPES.includes(fileMeta.mime_type as any)) {
      setError(`Unsupported file type. Acceptable formats: PNG, JPG, JPEG, GIF.`);
      return null;
    }

    if (fileMeta.size > MAX_FILE_SIZE) {
      setError('File size exceeds 10MB. Please upload a smaller file.');
      return null;
    }

    return {
      src: fileMeta.source,
      file: null,
      id: generateUniqueId(),
      meta: fileMeta,
    };
  }, []);

  const onSelectFiles = useCallback(
    (fileList: FileList) => {
      const newFiles: IFile[] = [];
      const totalFiles = files.length + fileList.length;

      for (const file of Array.from(fileList)) {
        const sanitizedFilename = sanitizeFilename(file.name);

        const sanitizedFile = new File([file], sanitizedFilename, {
          type: file.type,
          lastModified: file.lastModified,
        });

        const meta: IFileMeta = {
          mime_type: sanitizedFile.type as T_FILE_MIME_ENUM_TYPE,
          name: sanitizedFile.name,
          size: sanitizedFile.size,
          source: URL.createObjectURL(sanitizedFile),
        };

        const processedFile = processFileMeta(meta);
        if (processedFile) {
          newFiles.push({ ...processedFile, file: sanitizedFile });
        }

        if (files.length + newFiles.length >= maxFiles) {
          break;
        }
      }

      if (totalFiles > maxFiles) {
        toast({ title: `You can't upload more than ${maxFiles} files` }, { type: 'warning' });
      }

      if (newFiles.length > 0) {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setError(null);
      }
    },
    [files, processFileMeta, toast, maxFiles],
  );

  const addInitialFiles = useCallback(
    (initialFiles: IFileMeta[]) => {
      const newFiles: IFile[] = [];

      for (const fileMeta of initialFiles) {
        const processedFile = processFileMeta(fileMeta);
        if (processedFile) {
          newFiles.push({ ...processedFile, preventUpload: true });
        }

        if (files.length + newFiles.length >= maxFiles) {
          break;
        }
      }

      if (newFiles.length > 0) {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setError(null);
      }
    },
    [files, processFileMeta, maxFiles],
  );

  const deleteFile = useCallback((id: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  }, []);

  const onDiscardFiles = useCallback(() => {
    setFiles([]);
  }, []);

  return {
    files,
    onSelectFiles,
    addInitialFiles,
    deleteFile,
    error,
    onDiscardFiles,
  };
}
