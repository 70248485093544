export const TimezoneService = {
  getFormattedLocalTime(): string {
    const offset = -new Date().getTimezoneOffset();
    const sign = offset >= 0 ? '' : '-';
    const hours = Math.floor(Math.abs(offset) / 60)
      .toString()
      .padStart(2, '0');
    const minutes = (Math.abs(offset) % 60).toString().padStart(2, '0');
    return `${sign}${hours}:${minutes}`;
  },
};
