'use client';
import Lottie from 'lottie-react';

import animationData from '../../assets/animations/lottie.json';
import { useEffect, useState } from 'react';

export function CongratsScreen({ children }: React.PropsWithChildren) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true); // Ensure the component only runs after the component is mounted on the client
  }, []);
  return (
    <div className="relative flex h-fit min-h-screen flex-col items-center overflow-hidden">
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-10  bg-[#fbefc9]">
        {isClient && <Lottie animationData={animationData} loop={true} autoplay={true} />}
      </div>
      {children}
    </div>
  );
}
